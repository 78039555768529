import { ReactElement, PropsWithChildren } from 'react';
import { IMetaLocationClosedDates } from 'types/contentful';
import { format, isSameWeek } from 'date-fns';

import Map from './Map';
import MapMarker from './MapMarker';
import LocationMarkerSvg from './MapMarker/LocationMarkerSvg';

type Hours = {
  open: string;
  close: string;
};

export type OfficeHours = {
  [Sunday: string]: Hours | Hours[];
  Monday: Hours | Hours[];
  Tuesday: Hours | Hours[];
  Wednesday: Hours | Hours[];
  Thursday: Hours | Hours[];
  Friday: Hours | Hours[];
  Saturday: Hours | Hours[];
};

interface OfficeDetailsProps {
  siteName: string;
  officeName: string;
  hideEyeOnPin: boolean;
  hours: OfficeHours;
  hoursNotes?: string;
  backgroundColor?: 'primary' | 'secondary' | 'tertiary' | 'muted' | 'neutral';
  center: {
    lat: number;
    lng: number;
  };
  closedDates: IMetaLocationClosedDates | undefined;

  /** Marker Pin Icon Type */
  markerIcon: string;
}

interface ClosedDate {
  key: string;
  value: string;
  day?: string;
}

// used in order to keep the proper display order of days in loop
const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const checkClosedDates = (closedDates: IMetaLocationClosedDates | undefined): ClosedDate[] => {
  if (!closedDates) return [];
  const dates = closedDates.fields.dates as ClosedDate[];
  const today = new Date();

  return dates
    .filter((date) => isSameWeek(new Date(date.value), today))
    .map((date) => {
      return {
        ...date,
        day: DAYS[new Date(date.value).getDay()],
      };
    })
    .sort((a, b) => DAYS.indexOf(a.day) - DAYS.indexOf(b.day));
};

export default function OfficeDetails({
  siteName,
  officeName,
  hours,
  hideEyeOnPin,
  hoursNotes,
  children,
  center,
  backgroundColor,
  closedDates,
  markerIcon = 'Eye-V01',
}: PropsWithChildren<OfficeDetailsProps>): ReactElement {
  const closedList = checkClosedDates(closedDates);

  return (
    <div className={`flex p-4 md:px-10 lg:px-16 lg:py-10 w-full lg:bg-${backgroundColor}`}>
      <div className="w-full md:w-1/2 mt-5">
        <h2 className="block text-base lg:text-xl">
          {/* If sitename and officename are same then display only sitename else display both */}
          {siteName.trim() === officeName.trim() ? (
            <span className="hidden md:block">{siteName}</span>
          ) : (
            <span className="hidden md:block">{siteName + ' ' + officeName}</span>
          )}
          Hours of Operation:
        </h2>

        {closedList.length > 0 && (
          <div className="w-full md:w-2/3 mt-6 mb-6 text-red">
            We are closed for
            {closedList
              .map((closed) => ` ${closed.key} (${format(new Date(closed.value), 'EEE, LLL do')})`)
              .join(' and ')}
            .
          </div>
        )}

        <div className="w-full md:w-2/3 mt-4">
          {DAYS.map((day, i) => (
            <OfficeHours key={i} day={day} hours={hours[day]} closedList={closedList} />
          ))}
          {hoursNotes && (
            <span className="mt-3 block italic text-center font-medium">{hoursNotes}</span>
          )}
        </div>
        {children}
      </div>
      <div className="hidden md:block md:w-2/3" style={{ height: '430px' }}>
        <Map
          defaultZoom={16}
          center={center}
          draggable={false}
          options={{ fullscreenControl: false, zoomControl: false }}
        >
          <MapMarker {...center} focused>
            <LocationMarkerSvg
              title={officeName}
              hideEyeOnPin={hideEyeOnPin}
              classNames="text-primary-actual"
              focused
              markerIcon={markerIcon}
            />
          </MapMarker>
        </Map>
      </div>
    </div>
  );
}

function OfficeHours({
  day,
  hours,
  closedList,
}: {
  day: string;
  hours: Hours | Hours[];
  closedList: ClosedDate[];
}): ReactElement {
  const forceClosed = closedList.find((closed) => closed.day === day);

  const hoursElem = forceClosed ? (
    <Closed />
  ) : Array.isArray(hours) ? (
    <div className="flex flex-col text-right">
      {hours.map((hours, i) => (
        <Open key={i} from={hours.open} to={hours.close} />
      ))}
    </div>
  ) : hours.open && hours.close ? (
    <Open from={hours.open} to={hours.close} />
  ) : (
    <Closed />
  );

  return (
    <div className={`flex justify-between ${forceClosed ? 'text-red' : ''}`}>
      <span>{day}</span>
      {hoursElem}
    </div>
  );
}

function Closed(): ReactElement {
  return <span>Closed</span>;
}

function Open({ from, to }: { from: string; to: string }): ReactElement {
  return (
    <span>
      {from} - {to}
    </span>
  );
}
