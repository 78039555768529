import { Transition } from '@headlessui/react';
import React, { PropsWithChildren, ReactElement } from 'react';

interface MarkerProps {
  lat?: number;
  lng?: number;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => void;
  renderInfoCard?: () => ReactElement;
  focused?: boolean;
  showCard?: boolean;
}

export default function MapMarker({
  style = {},
  onClick = () => {
    return;
  },
  children,
  renderInfoCard,
  focused = false,
  showCard = false,
}: PropsWithChildren<MarkerProps>): ReactElement {
  return (
    <div
      className={`relative group ${focused ? 'z-20' : 'z-10'}`}
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyDown={onClick}
      style={{ position: 'relative', ...style }}
    >
      {children}
      {/* info card */}
      {renderInfoCard && (
        <Transition
          show={showCard}
          enter="transition ease-out duration-200"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          {renderInfoCard()}
        </Transition>
      )}
    </div>
  );
}
